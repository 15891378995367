<template>
  <div class="static-content-wrapper">
    <h1>Online Privacy</h1>
    <p>
      At Agility Chimp we take your privacy serious. We will not sell your
      email, phone number, or any other private information to a third party!
      That being said, we will use your information in the following ways:
    </p>

    <p>
      1. For each visitor to our website (www.agilitychimp.com), our web server
      automatically recognizes the visitor’s domain name, but not their email
      address. Our website also incorporates cookies.
    </p>

    <p>
      2. Agility Chimp automatically collects and stores domain names, but not
      the email address of visitors to our website. We routinely store and
      analyze aggregate information on what pages persons access or visit.
    </p>

    <p>
      3. The information Agility Chimp collects, stores and analyzes is used to
      improve the way we do business with our current and potential customers.
    </p>

    <p>
      4. Generally, if a visitor supplies us with their postal address online,
      they will only receive the information for which they provided us their
      address. Although it is not standard practice, visitors may receive postal
      mailings from us with information on new products and services or upcoming
      events. If a visitor does not wish to receive such mailings, they should
      indicate this preference each time information is submitted to Agility
      Chimp online.
    </p>

    <p>
      5. Agility Chimp stores email addresses and correspondence of those who
      communicate with us via email. Although it is not standard practice, if a
      visitor has submitted email on a website form, or has written to any email
      addresses located on agilitychimp.com, they may receive mailings from us
      with information on new products and services or upcoming events. If a
      visitor does not wish to receive such mailings, they should indicate this
      preference each time they submit email to any address located on
      agilitychimp.com.
    </p>

    <p>
      6. Persons who supply Agility Chimp with their telephone numbers online
      may receive calls from us regarding their inquiries, service requests or
      orders they have submitted online. If a visitor does not wish to receive
      such telephone calls, they should indicate this preference each time they
      submit information to Agility Chimp online.
    </p>

    <p>
      7. As a standard practice, Agility Chimp does not share any
      personally-identifiable or private customer information submitted online
      with third parties, unless they are acting as an intermediary. Some
      examples of intermediaries include shippers, Internet service providers,
      fulfillment houses and credit card companies.
    </p>

    <p>
      8. Some web areas located within the agilitychimp.com domain space may
      post a tailored consumer privacy policy depending on the mission of that
      particular web area.
    </p>

    <p>
      9. This agilitychimp.com Online Consumer Privacy Policy is subject to
      change. If changes occur, they will be posted on www.agilitychimp.com.
    </p>

    <p>
      10. From time-to-time, Agility Chimp may collect IP address information
      gathered from visitors to www.agilitychimp.com to generate marketing
      campaigns. Agility Chimp may also use this IP address information to
      contact visitors with regard to Agility Chimp products and services.
    </p>
  </div>
</template>
